import useService, {
  HookResult,
  useCachedService,
  SWRConfiguration,
} from '@banyudu/use-service'
import { useAtomValue } from 'jotai'
import { auth0SignInAtom } from '@/atoms'
import { getLocalStorage } from '@/utils'

type AuthedService<Result = any, Params = any> = (
  params?: Params,
) => HookResult<Result>
export interface CachedHookResult<Result = any> extends HookResult<Result> {
  refresh: () => void
}

// type CachedService<Result = any, Params = any> = (params?: Params) => CachedHookResult<Result>
type AuthedCachedService<Result = any, Params = any> = (
  params?: Params,
) => CachedHookResult

const NOT_AUTH = Symbol('NOT_AUTH')

const useAuthedService = <Result = any, Params = any>(
  fetcher: (p: Params) => Promise<Result>,
  skip?: (p: Params) => boolean,
  swrOptions?: SWRConfiguration,
): AuthedService<Result, Params> => {
  const authGuardFetcher = (
    params: Params | typeof NOT_AUTH,
  ): Promise<Result | null> => {
    if (params === NOT_AUTH) {
      return Promise.resolve(null)
    }
    return fetcher(params)
  }

  const innerHook = useService(authGuardFetcher, skip, swrOptions)

  const result = (params?: Params): HookResult<Result> => {
    const hasSignIn = useAtomValue(auth0SignInAtom)
    const parsedParams = hasSignIn ? params : NOT_AUTH
    const res: HookResult<Result> = innerHook(parsedParams as any)
    return res
  }
  return result
}

export const useAuthedCachedService = <Result = any, Params = any>(
  fetcher: (p: Params) => Promise<Result>,
  skip?: (p: Params) => boolean,
  swrOptions?: SWRConfiguration,
): AuthedCachedService<Result, Params> => {
  const authGuardFetcher = (params: Params | typeof NOT_AUTH) => {
    if (params === NOT_AUTH) {
      return Promise.resolve(null)
    }
    return fetcher(params)
  }

  const innerHook = useCachedService(authGuardFetcher, skip, swrOptions)

  const result = (params?: Params): CachedHookResult<Result> => {
    const hasSignIn = useAtomValue(auth0SignInAtom)
    const token = getLocalStorage('token', { raw: true })
    const parsedParams = hasSignIn && token ? params : NOT_AUTH
    const res = innerHook(parsedParams as any)
    return res
  }
  return result
}

export default useAuthedService
