import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cls } from '@/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-body-md font-medium ring-offset-surface transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-surface hover:bg-surface-hover active:bg-surface-hover',
  {
    variants: {
      variant: {
        primary:
          'bg-surface-primary hover:bg-surface-primary-hover text-text-on-color bg-surface-primary hover:bg-surface-primary-hover active:bg-surface-primary-hover',
        destructive: 'bg-surface-critical text-text-critical',
        outline: 'border border-border bg-surface',
        secondary:
          'bg-surface-subdued text-text-subdued hover:bg-surface-subdued',
        transparent: 'text-text',
        link: 'text-text-interactive',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      // variant: 'outline',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        ref={ref}
        className={cls(buttonVariants({ variant, size, className }))}
        role='button'
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
