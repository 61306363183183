'use client'

import { forwardRef, useRef, useState, MouseEvent, useEffect, useCallback } from 'react'
import { cls } from '@/utils'
import IconLoading from '@/public/assets/loading.svg'
import { Button as BaseButton, ButtonProps as BaseButtonProps } from '@/components/ui/button'
import dayjs from 'dayjs'
import { NO_OUTLINE_STYLE } from '@/constants'
import Tooltip, { TooltipProps } from '../tooltip'

export interface ButtonProps extends BaseButtonProps {
  loading?: boolean
  loadingClassName?: string
  coldDown?: Date | null
  tooltip?: string
  tooltipProps?: TooltipProps
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loading,
      children,
      onClick,
      disabled,
      coldDown,
      tooltip,
      tooltipProps,
      onMouseEnter,
      onMouseLeave,
      loadingClassName,
      ...props
    },
    ref,
  ) => {
    const [waitTime, setWaitTime] = useState<number | null>(null)
    const timerRef = useRef<any>(null)
    const isCountingDown = !!waitTime

    const updateWaitTime = useCallback(() => {
      const coldDownDate = dayjs(coldDown)
      if (!coldDown || !coldDownDate.isValid() || coldDownDate.isBefore(dayjs())) {
        if (timerRef.current) {
          clearInterval(timerRef.current)
        }
        setWaitTime(0)
        return
      }
      setWaitTime(Math.max(0, coldDownDate.diff(dayjs(), 'second')))
    }, [coldDown])

    const startCountDown = useCallback(() => {
      const coldDownDate = dayjs(coldDown)
      if (!coldDown || !coldDownDate.isValid() || coldDownDate.isBefore(dayjs())) {
        return
      }
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
      timerRef.current = setInterval(updateWaitTime, 1000)
    }, [coldDown, updateWaitTime])

    const popoverTimerRef = useRef<any>(null)

    useEffect(() => {
      startCountDown()
    }, [startCountDown])

    const handleClick = useCallback(
      async (e: MouseEvent<HTMLButtonElement>) => {
        try {
          await onClick?.(e)
        } catch (error) {
          console.error(`onClick error: ${error}`)
        }
        startCountDown()
      },
      [onClick, startCountDown],
    )

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleMouseEnter = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (popoverTimerRef.current) {
          clearTimeout(popoverTimerRef.current)
        }
        popoverTimerRef.current = setTimeout(() => {
          setTooltipOpen(true)
        }, 300)

        onMouseEnter?.(e)
      },
      [onMouseEnter],
    )

    const handleMouseLeave = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (popoverTimerRef.current) {
          clearTimeout(popoverTimerRef.current)
        }
        popoverTimerRef.current = setTimeout(() => {
          setTooltipOpen(false)
        }, 300)
        onMouseLeave?.(e)
      },
      [onMouseLeave],
    )

    const button = (
      <BaseButton
        {...props}
        ref={ref}
        disabled={disabled || isCountingDown}
        className={cls(
          'gap-2 relative focus-visible:ring-0 focus-visible:ring-offset-0 duration-75',
          'min-w-max',
          NO_OUTLINE_STYLE,
          className,
        )}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {loading ? (
          <div className='flex min-w-max'>
            <div className='absolute size-full flex justify-center items-center box-border p-2 inset-0'>
              <IconLoading
                className={cls(
                  'animate-spin max-w-full max-h-full text-text-interactive',
                  props.variant === 'primary' ? 'text-text-on-color' : '',
                  loadingClassName,
                )}
              />
            </div>
            <div className={cls('min-w-max', 'invisible')}>{children}</div>
          </div>
        ) : isCountingDown ? (
          <span>Wait {waitTime}s</span>
        ) : (
          children
        )}
      </BaseButton>
    )

    if (tooltip) {
      return (
        <Tooltip trigger={button} align='center' open={tooltipOpen} {...tooltipProps}>
          <span className='text-text-on-color'>{tooltip}</span>
        </Tooltip>
      )
    }
    return button
  },
)

Button.displayName = BaseButton.displayName

export default Button
