import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cls } from '@/utils'
import Button from '@/components/button'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import Broadcasts from '../broadcasts'
import useGlobalPadding from '@/hooks/useGlobalPadding'

const DialogCloseButtonClassName = 'DialogCloseButton'

const Dialog = DialogPrimitive.Root

const DialogTrigger = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Trigger
    ref={ref}
    className={cls('', className)}
    {...props}
  />
))
DialogTrigger.displayName = DialogPrimitive.Trigger.displayName

const DialogPortal = DialogPrimitive.Portal

const DialogClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close ref={ref} className={cls('', className)} {...props} />
))
DialogClose.displayName = DialogPrimitive.Close.displayName

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cls(
      'fixed inset-0 z-60 bg-transparent backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const legacyTranslateStyle = {
  transform: 'translate(-50%, -50%)',
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    overlayClassName?: string
    closeClassName?: string
    withBroadcasts?: boolean
    onCancel?: () => void
  }
>(
  (
    {
      className,
      overlayClassName,
      closeClassName,
      withBroadcasts,
      children,
      onCancel,
      ...props
    },
    ref,
  ) => {
    const { absoluteClassName } = useGlobalPadding()
    return (
      <DialogPortal>
        <DialogOverlay
          className={cls(
            'backdrop-blur-[4px] bg-surface-base/70 transition-none pb-16 md:pb-0',
            withBroadcasts ? absoluteClassName : '',
            overlayClassName,
          )}
        >
          <DialogPrimitive.Content
            ref={ref}
            className={cls(
              'absolute left-[50%] top-[50%] z-60 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border p-6 shadow-lg duration-200 data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg md:w-full overflow-y-auto no-scrollbar bg-surface',
              className,
            )}
            style={legacyTranslateStyle}
            {...props}
          >
            {withBroadcasts && <Broadcasts className='md:hidden' />}
            {children}
            <DialogPrimitive.Close asChild>
              <Button
                variant='transparent'
                className={cls(
                  'absolute p-0 size-10 rounded-lg right-2 md:right-3.5 top-2 md:top-3.5 focus-visible:shadow-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted border-0 shadow-none',
                  DialogCloseButtonClassName,
                  closeClassName,
                )}
                aria-label='dialog close button'
                onClick={onCancel}
              >
                <IconClose className='size-6 text-icon' />
                <span className='sr-only'>Close</span>
              </Button>
            </DialogPrimitive.Close>
          </DialogPrimitive.Content>
        </DialogOverlay>
      </DialogPortal>
    )
  },
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cls(
      'flex flex-col space-y-1.5 text-center sm:text-left',
      className,
    )}
    {...props}
  />
)
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cls(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
)
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cls('text-heading-lg font-semibold tracking-tight', className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cls('text-body-md text-muted', className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogCloseButtonClassName,
}
